import { createContext } from 'react';
import type { Tenant } from './model';

export interface TenantContext {
  tenantId?: Tenant;

  updateTenant(tenant: Tenant): void;
}

export const TenantContext = createContext<TenantContext>({
  tenantId: undefined,
  updateTenant: () => {
    return;
  },
});
