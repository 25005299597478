import { superFetch } from './superFetch';
import { defaultHeaders } from './defaultHeaders';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

interface GetOptions<S, P> {
  authContext: AuthenticationContext;
  url: [P] extends [never] ? string : (params: P) => string;
  headers?: Headers | ((params: P) => Headers);
  /** @deprecated Please use `select` of `useApiQuery` instead. */
  transform?: (result: any) => S;
}

export function get<P = never, S = unknown>({
  url,
  headers = defaultHeaders(),
  transform = (d: any) => d as unknown as S,
  authContext,
}: GetOptions<S, P>): P extends never
  ? () => Promise<S>
  : (dataIn: { params: P }) => Promise<S> {
  // @ts-expect-error dynamic dataIn type
  // eslint-disable-next-line complexity
  return async function get(dataIn) {
    const finalUrl = typeof url === 'string' ? url : url(dataIn?.params);
    const finalHeaders =
      typeof headers === 'function' ? headers(dataIn?.params) : headers;
    const result = await superFetch(authContext, finalUrl, {
      method: 'GET',
      headers: finalHeaders,
    });

    const data = await result.json();
    if (data && data.statusCode >= 400) {
      throw new Error(data.message ?? 'Unknown error');
    }
    return transform(data);
  };
}
