import { isWebEnvironment } from './isWebEnvironment';

export const getFetchOptions = (): {
  headers: Headers;
  credentials: RequestCredentials | undefined;
} => {
  // @ts-expect-error //global is untyped
  const application = window.global.pflegenaviApplication;
  // @ts-expect-error //global is untyped
  const applicationVersion = window.global.pflegenaviApplicationVersion;

  // @ts-expect-error //global is untyped
  const tenant = window.global.tenant;

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-PN-Application': application,
    'X-PN-Application-Version': applicationVersion,
    ...(tenant ? { 'X-PN-Tenant': tenant } : {}),
  });

  const credentials = isWebEnvironment() ? 'include' : undefined;

  return { headers, credentials };
};
