import { superFetch } from './superFetch';
import { defaultHeaders } from './defaultHeaders';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

interface PostOptions<T, R, S, P> {
  authContext: AuthenticationContext;
  url: string | ((params: P) => string);
  headers?: Headers | ((params: P) => Headers);
  /** @deprecated Please use `select` of `useApiQuery` instead. */
  transform?: (result: R) => S;
  transformBody?: (data: T) => unknown;
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}

export function modify<T, R, S = R, P = never>({
  url,
  headers = defaultHeaders(),
  transform = (d: R) => d as unknown as S,
  transformBody = (d: T) => d as unknown as T,
  authContext,
  method = 'POST',
}: PostOptions<T, R, S, P>): (dataIn: { params: P; body: T }) => Promise<S> {
  // eslint-disable-next-line complexity
  return async function post(dataIn: { params: P; body: T }) {
    const finalUrl = typeof url === 'string' ? url : url(dataIn.params);
    const finalHeaders =
      typeof headers === 'function' ? headers(dataIn.params) : headers;

    let body: any = dataIn?.body;
    if (body && transformBody) {
      body = transformBody(body);
      if (typeof body === 'string') {
        //
      } else if (body instanceof FormData) {
        //
      } else {
        body = JSON.stringify(body);
      }
    }

    const result = await superFetch(authContext, finalUrl, {
      method,
      headers: finalHeaders,
      body: body,
    });

    if (result.status === 204) {
      return transform(undefined as unknown as R);
    }

    const data = await result.json();
    if (data && data.statusCode >= 400) {
      throw new Error(data.message ?? 'Unknown error');
    }

    return transform(data);
  };
}

export interface ModifyResponse<T> {
  headers: Headers;
  data: T;
}

export function modifyWithHeaders<T, R, S = R, P = never>({
  url,
  headers = defaultHeaders(),
  transform = (d: R) => d as unknown as S,
  transformBody = (d: T) => d as unknown as T,
  authContext,
  method = 'POST',
}: PostOptions<T, R, S, P>): (dataIn: {
  params: P;
  body: T;
}) => Promise<ModifyResponse<S>> {
  // eslint-disable-next-line complexity
  return async function post(dataIn: { params: P; body: T }) {
    const finalUrl = typeof url === 'string' ? url : url(dataIn.params);
    const finalHeaders =
      typeof headers === 'function' ? headers(dataIn.params) : headers;
    const result = await superFetch(authContext, finalUrl, {
      method,
      headers: finalHeaders,
      body:
        dataIn?.body === undefined
          ? undefined
          : JSON.stringify(
              transformBody ? transformBody(dataIn.body) : dataIn.body
            ),
    });

    const data = await result.json();
    if (data && data.statusCode >= 400) {
      throw new Error(data.message ?? 'Unknown error');
    }

    return { headers: result.headers, data: transform(data) };
  };
}
