export enum Environment {
  Local = 'local',
  Staging = 'staging',
  Production = 'production',
}

const supportedTopLevelDomains = ['.at', '.app', '.it'];

// eslint-disable-next-line complexity
export function getDomain(hostname?: string): string {
  if (hostname?.includes('localhost')) {
    return 'localhost';
  }

  if (hostname?.startsWith('http://')) {
    hostname = hostname.replace('http://', '');
  }
  if (hostname?.startsWith('https://')) {
    hostname = hostname.replace('https://', '');
  }

  if (hostname?.includes('/')) {
    hostname = hostname.split('/')[0];
  }

  const parts = (hostname ?? window?.location?.hostname)?.split('.') || [];
  if (parts.length === 1) {
    return 'localhost';
  }
  const final = parts.slice(-2).join('.') || '';

  // This definitely can be improved
  if (
    supportedTopLevelDomains.some((topLevelDomain) =>
      final.endsWith(topLevelDomain)
    )
  ) {
    return final;
  }
  return 'localhost';
}

export function getEnvironment(hostname?: string): Environment {
  const domain = getDomain(hostname);
  if (domain === 'localhost') {
    return Environment.Local;
  }

  if (isStaging(domain, hostname)) {
    return Environment.Staging;
  } else if (isProduction(domain, hostname)) {
    return Environment.Production;
  }
  return Environment.Local;
}

function isProduction(topLevelDomain: string, hostname?: string): boolean {
  return (
    !isStaging(topLevelDomain, hostname) &&
    (hostname ?? window?.location?.hostname)?.includes(topLevelDomain)
  );
}

function isStaging(topLevelDomain: string, hostname?: string): boolean {
  return (hostname ?? window?.location?.hostname)?.includes(
    `staging.${topLevelDomain}`
  );
}

enum WebApp {
  Admin = 'admin',
  NursingHome = 'nursing-home',
  ServiceProvider = 'service-provider',
  FamilyMember = 'family-member',
}

// eslint-disable-next-line complexity
function getWebApp(): WebApp {
  const environment = getEnvironment();
  if (environment === Environment.Local) {
    if (window?.location?.hostname === 'localhost') {
      if (window?.location?.port === '3000') {
        return WebApp.NursingHome;
      } else if (window?.location?.port === '3001') {
        return WebApp.FamilyMember;
      } else if (window?.location?.port === '3002') {
        return WebApp.Admin;
      } else if (window?.location?.port === '3003') {
        return WebApp.ServiceProvider;
      }
    }
  }

  if (window?.location?.hostname?.includes('service-provider')) {
    return WebApp.ServiceProvider;
  } else if (window?.location?.hostname?.includes('family-member')) {
    return WebApp.FamilyMember;
  } else if (window?.location?.hostname?.includes('admin')) {
    return WebApp.Admin;
  }
  return WebApp.NursingHome;
}

function getNursingHomeApiEndpoints(topLevelDomain: string, tenantId: string) {
  return {
    [Environment.Local]: `http://${topLevelDomain}:3005/api/${tenantId}`,
    [Environment.Staging]: `https://api.staging.${topLevelDomain}/api/${tenantId}`,
    [Environment.Production]: `https://api.${topLevelDomain}/api/${tenantId}`,
  };
}

export const getApiBaseUrl = (tenantId: string, apiUrl?: string): string => {
  if (apiUrl) {
    return `${apiUrl}/${tenantId}`;
  }

  const topLevelDomain = getDomain();

  const nursingHomeApiEndpoints = getNursingHomeApiEndpoints(
    topLevelDomain,
    tenantId
  );

  const defaultEndpoints = {
    [WebApp.NursingHome]: nursingHomeApiEndpoints,
    [WebApp.Admin]: nursingHomeApiEndpoints,
    [WebApp.FamilyMember]: nursingHomeApiEndpoints,
    [WebApp.ServiceProvider]: {
      [Environment.Local]: `http://${topLevelDomain}:3006/api/${tenantId}`,
      [Environment.Staging]: `https://api.staging.${topLevelDomain}/api/service_provider`,
      [Environment.Production]: `https://api.${topLevelDomain}/api/service_provider`,
    },
  };

  return defaultEndpoints[getWebApp()][getEnvironment()];
};

// eslint-disable-next-line complexity
export const getPhoenixApiBaseUrl = (apiUrl: string | undefined): string => {
  let environment = getEnvironment();
  if (apiUrl && apiUrl.includes('staging')) {
    environment = Environment.Staging;
  } else if (apiUrl && apiUrl.includes('local')) {
    environment = Environment.Local;
  } else if (apiUrl) {
    environment = Environment.Production;
  }

  const topLevelDomain = getDomain(apiUrl);

  const defaultEndpoints = {
    [Environment.Local]: `http://${topLevelDomain}:4000`,
    [Environment.Staging]: `https://phoenix.staging.${topLevelDomain}`,
    [Environment.Production]: `https://phoenix.${topLevelDomain}`,
  };

  return defaultEndpoints[environment];
};

// eslint-disable-next-line complexity
export const getPhoenixApiTenantUrl = (
  tenantId: string,
  apiUrl: string | undefined
): string => {
  let environment = getEnvironment();
  if (apiUrl && apiUrl.includes('staging')) {
    environment = Environment.Staging;
  } else if (apiUrl && apiUrl.includes('local')) {
    environment = Environment.Local;
  } else if (apiUrl) {
    environment = Environment.Production;
  }

  const topLevelDomain = getDomain(apiUrl);

  const defaultEndpoints = {
    [Environment.Local]: `http://${topLevelDomain}:4000/api/${tenantId}`,
    [Environment.Staging]: `https://phoenix.staging.${topLevelDomain}/api/${tenantId}`,
    [Environment.Production]: `https://phoenix.${topLevelDomain}/api/${tenantId}`,
  };

  return defaultEndpoints[environment];
};

// eslint-disable-next-line complexity
export const getPhoenixApiWebsocketBaseUrl = (
  apiUrl: string | undefined
): string => {
  let environment = getEnvironment();
  if (apiUrl && apiUrl.includes('staging')) {
    environment = Environment.Staging;
  } else if (apiUrl && apiUrl.includes('local')) {
    environment = Environment.Local;
  } else if (apiUrl) {
    environment = Environment.Production;
  }

  const topLevelDomain = getDomain(apiUrl);

  const defaultEndpoints = {
    [Environment.Local]: `ws://${topLevelDomain}:4000`,
    [Environment.Staging]: `wss://phoenix.staging.${topLevelDomain}`,
    [Environment.Production]: `wss://phoenix.${topLevelDomain}`,
  };

  return defaultEndpoints[environment];
};

export const getNursingHomeBaseUrl = (
  tenantId: string,
  apiUrl?: string
): string => {
  if (apiUrl) {
    return `${apiUrl}/${tenantId}`;
  }
  const topLevelDomain = getDomain();
  return getNursingHomeApiEndpoints(topLevelDomain, tenantId)[getEnvironment()];
};
