import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { Tenant } from './model';
import { TenantContext } from './TenantContext';

interface TenantContextProviderProps {
  tenantId?: string;
}

export const TenantContextProvider: FC<
  PropsWithChildren<TenantContextProviderProps>
  // eslint-disable-next-line complexity
> = ({ children, tenantId: tenantIdIn }) => {
  const setTenantContext = useRef<Dispatch<SetStateAction<TenantContext>>>();

  const updateTenant = useCallback(
    (tenant: Tenant) => {
      setTenantContext?.current?.((tenantContext) => ({
        ...tenantContext,
        tenantId: tenant,
      }));
    },
    [setTenantContext]
  );

  let tenantId: string | undefined =
    tenantIdIn ?? (Tenant.DefaultTenant as string);
  if (!tenantIdIn && window && window?.location) {
    if (window.location.host === 'localhost:3001') {
      tenantId = undefined;
    } else if (window.location.host === 'localhost:3002') {
      tenantId = Tenant.Master;
    } else if (window.location.host === 'admin.staging.pflegenavi.at') {
      tenantId = Tenant.Master;
    } else if (window.location.hostname === 'test.extranet.pflegenavi.at') {
      tenantId = Tenant.DefaultTenant;
    } else if (
      window.location.hostname === 'family-member.staging.pflegenavi.at'
    ) {
      tenantId = undefined;
    } else if (window.location.hostname === 'family-member.pflegenavi.at') {
      tenantId = undefined;
    } else if (
      window.location.hostname.endsWith('.test.extranet.pflegenavi.at')
    ) {
      tenantId = window.location.hostname.slice(
        0,
        -'.test.extranet.pflegenavi.at'.length
      );
    } else if (window.location.hostname.endsWith('.staging.pflegenavi.at')) {
      tenantId = window.location.hostname.slice(
        0,
        -'.staging.pflegenavi.at'.length
      );
    } else if (window.location.hostname.endsWith('.extranet.pflegenavi.at')) {
      tenantId = window.location.hostname.slice(
        0,
        -'.extranet.pflegenavi.at'.length
      );
    } else if (window.location.hostname.endsWith('.pflegenavi.at')) {
      tenantId = window.location.hostname.slice(0, -'.pflegenavi.at'.length);
    } else if (window.location.host.includes('.localhost')) {
      tenantId = window.location.host.split('.')[0];
    }
  }

  const [tenantContext, setTenantContext_] = useState<TenantContext>({
    tenantId: tenantId as Tenant,
    updateTenant,
  });

  setTenantContext.current = setTenantContext_;

  // @ts-expect-error //global is untyped
  window.global.tenant = tenantContext.tenantId;

  return (
    <TenantContext.Provider value={tenantContext}>
      {children}
    </TenantContext.Provider>
  );
};
export const useTenantId = (): Tenant | undefined => {
  return useContext(TenantContext).tenantId;
};
export const useUpdateTenantId = (): {
  updateTenant: (tenant: Tenant) => void;
} => {
  return {
    updateTenant: useContext(TenantContext).updateTenant,
  };
};
