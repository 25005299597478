import { useMemo } from 'react';
import type { UseQueryResult } from 'react-query';

export type UseReceiptImagesType = (
  receiptImageIds: string[] | undefined
) => UseQueryResult<
  | {
      [p: string]: string | undefined;
    }
  | undefined
>;

interface UseUrlsToImagesProps {
  receiptImageIds: string[] | undefined;
  useReceiptImages: UseReceiptImagesType;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUrlsToImages = ({
  receiptImageIds,
  useReceiptImages,
}: UseUrlsToImagesProps) => {
  const { data: receiptImagesObject, isLoading: ImageUrlsLoading } =
    useReceiptImages(receiptImageIds);

  const receiptImages: string[] | undefined = useMemo(() => {
    if (receiptImagesObject) {
      return Object.values(receiptImagesObject)
        .filter((item) => item !== undefined)
        .map((item) => item?.toString() ?? '');
    }
    return undefined;
  }, [receiptImagesObject]);

  return {
    ImageUrlsLoading,
    receiptImages,
  };
};
