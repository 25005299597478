import type { FC, ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import { useTenantId } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import {
  useAuthenticationInternal,
  useTestMode,
} from '@pflegenavi/frontend/authentication';

export interface ApiProviderProps {
  children: ReactNode;
  apiUrl?: string;
}

export interface Api {
  authContext: AuthenticationContext;
}

interface MakeApiProviderOptions<T extends Api> {
  name: string;
  ApiContext: React.Context<T | undefined>;
  newApi: (
    tenantId: Tenant,
    auth: AuthenticationContext,
    apiUrl?: string,
    testMode?: boolean
  ) => T;
}

interface MakeApiProviderOptionsWithoutTenant<T extends Api> {
  name: string;
  ApiContext: React.Context<T | undefined>;
  newApi: (
    auth: AuthenticationContext,
    apiUrl?: string,
    testMode?: boolean
  ) => T;
}

type UseApi<T extends Api> = <Optional extends boolean = false>(
  optional?: Optional
) => Optional extends true ? T | undefined : T;

export function makeApiProviderWithoutTenant<T extends Api>({
  name,
  newApi,
  ApiContext,
}: MakeApiProviderOptionsWithoutTenant<T>): {
  ApiProvider: FC<ApiProviderProps>;
  useApi: UseApi<T>;
} {
  const ApiProvider: FC<ApiProviderProps> = ({ children, apiUrl }) => {
    const auth = useAuthenticationInternal();
    const [testMode] = useTestMode();

    const api = useMemo(() => {
      return newApi(auth, apiUrl, testMode);
    }, [auth, apiUrl, testMode]);

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
  };

  const useApi = (): T => {
    const api = useContext(ApiContext);
    if (!api) {
      throw new Error(`Missing ${name} provider`);
    }
    return api;
  };

  return {
    ApiProvider,
    useApi,
  };
}

export function makeApiProvider<T extends Api>({
  name,
  newApi,
  ApiContext,
}: MakeApiProviderOptions<T>): {
  ApiProvider: FC<ApiProviderProps>;
  useApi: UseApi<T>;
} {
  const ApiProvider: FC<ApiProviderProps> = ({ children, apiUrl }) => {
    const tenantId = useTenantId();
    const auth = useAuthenticationInternal();
    const [testMode] = useTestMode();

    const api = useMemo(() => {
      return tenantId !== undefined
        ? newApi(tenantId, auth, apiUrl, testMode)
        : undefined;
    }, [auth, tenantId, apiUrl, testMode]);

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
  };

  const useApi = <Optional extends boolean = false>(
    optional?: Optional
  ): Optional extends true ? T | undefined : T => {
    const api = useContext(ApiContext);
    if (!optional && !api) {
      throw new Error(`Missing ${name} provider`);
    }
    return api as Optional extends true ? T | undefined : T;
  };

  return {
    ApiProvider,
    useApi,
  };
}
